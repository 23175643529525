export default {
  namespaced: true,
  state: {
    zoomAccountId: null,
    wsocket: null,
    isWsConnected: false,
    agents: [],
    fromDate: '',
    toDate: '',
    callsSearch: '',
    states: {
      websocket_connected: 5,
      user_presence_status_updated: 10,
      phone_caller_ringing: 20,
      phone_caller_connected: 21,
      phone_caller_ended: 22,
      phone_callee_ringing: 30,
      phone_callee_answered: 31,
      phone_callee_ended: 32,
      phone_callee_missed: 33,
      phone_callee_rejected: 34,
      user_personal_notes_updated: 40
    }
  },
  mutations: {
    setZoomAccountId (state, zoomAccountId) {
      state.zoomAccountId = zoomAccountId
    },
    setWebSocket (state, wsock) {
      state.wsocket = wsock
    },
    setWebSocketConnected (state, isWsConnected) {
      state.isWsConnected = isWsConnected
    },
    setAgents (state, agents) {
      state.agents = agents
    },
    setFromDate (state, fromDate) {
      state.fromDate = fromDate
    },
    setToDate (state, toDate) {
      state.toDate = toDate
    },
    setCallsSearch (state, search) {
      state.callsSearch = search
    }
  },
  actions: {
    SAVE_WEBSOCKET (context, val) {
      context.commit('setWebSocket', val)
    },
    PROCESS_WEBSOCKET_MESSAGE (context, val) {
      console.log('WSMSG', val)
      console.log('websocket_connected', context.state.states.websocket_connected)
      if (val.type === context.state.states.websocket_connected) {
        const bdy = JSON.parse(val.body)
        if ('id' in bdy) {
          console.dir(bdy)
          const agtUserId = bdy.id.split(':')[1].toLowerCase()
          const eventType = bdy.id.split(':')[0]
          const agents = context.state.agents.map(agt => {
            if (eventType === 'email') {
              if (agt.email.toLowerCase() === agtUserId) {
                agt.presence_status = bdy.status
              }
            } else if (eventType === 'note') {
              if (agt.email.toLowerCase() === agtUserId) {
                agt.personal_notes = bdy.status
              }
            } else if (eventType === 'phone') {
              if (agt.extension_number === agtUserId) {
                agt.phone_status = bdy.status
              }
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.user_presence_status_updated) {
        const bdy = JSON.parse(val.body)
        if ('email' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.email === bdy.email) {
              agt.presence_status = bdy.presence_status
              // agt.agent_id = bdy.id
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.phone_caller_ringing) {
        const bdy = JSON.parse(val.body)
        if ('caller' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.extension_number === `${bdy.caller.extension_number}`) {
              agt.phone_status = 'Ringing'
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.phone_caller_connected) {
        const bdy = JSON.parse(val.body)
        if ('caller' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.extension_number === `${bdy.caller.extension_number}`) {
              agt.phone_status = 'Talking'
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.phone_caller_ended) {
        const bdy = JSON.parse(val.body)
        if ('caller' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.extension_number === `${bdy.caller.extension_number}`) {
              agt.phone_status = ''
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.phone_callee_ringing) {
        const bdy = JSON.parse(val.body)
        if ('callee' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.extension_number === `${bdy.callee.extension_number}`) {
              agt.phone_status = 'Ringing'
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.phone_callee_answered) {
        const bdy = JSON.parse(val.body)
        if ('callee' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.extension_number === `${bdy.callee.extension_number}`) {
              agt.phone_status = 'Talking'
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.phone_callee_ended) {
        const bdy = JSON.parse(val.body)
        if ('callee' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.extension_number === `${bdy.callee.extension_number}`) {
              agt.phone_status = ''
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.phone_callee_missed) {
        const bdy = JSON.parse(val.body)
        if ('callee' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.extension_number === `${bdy.callee.extension_number}`) {
              agt.phone_status = ''
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.phone_callee_rejected) {
        const bdy = JSON.parse(val.body)
        if ('callee' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.extension_number === `${bdy.callee.extension_number}`) {
              agt.phone_status = ''
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
      if (val.type === context.state.states.user_personal_notes_updated) {
        const bdy = JSON.parse(val.body)
        if ('email' in bdy) {
          console.dir(bdy)
          const agents = context.state.agents.map(agt => {
            if (agt.email === bdy.email) {
              agt.personal_notes = bdy.personal_notes
            }
            return agt
          })
          context.commit('setAgents', agents)
        }
      }
    },
    STOP_WEBSOCKET (context) {
      console.log('closing websocket')
      context.state.wsocket.close()
    }
  },
  getters: {
    zoomAccountId: state => state.zoomAccountId,
    isWsConnected: state => state.isWsConnected,
    agents: state => state.agents,
    fromDate: state => state.fromDate,
    toDate: state => state.toDate,
    callsSearch: state => state.callsSearch
  }
}
