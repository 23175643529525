export default {
  namespaced: true,
  state: {},
  actions: {
    DO_TOAST (_, { message, variant }) {
      this._vm.$bvToast.toast(message, {
        title: 'ATEA WXCC',
        autoHideDelay: variant === 'danger' ? 10000 : 2500,
        variant: variant,
        appendToast: true,
        toaster: 'b-toaster-top-center'
      })
    }
  }
}
