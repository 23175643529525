<template>
  <div>
    <!-- <router-link
      class="list-group-item list-group-item-action bg-light"
      to="/wxcc/queues" v-if="isWebexAuth"
    ><font-awesome-icon icon="table" /> WxCC Queues</router-link>
    <router-link
      class="list-group-item list-group-item-action bg-light"
      to="/wxcc/agents" v-if="isWebexAuth"
    ><font-awesome-icon icon="table" /> WxCC Agents</router-link> -->
    <router-link
      class="list-group-item list-group-item-action bg-light"
      to="/wxcc/dashboards"  v-if="isWebexAuth"
    ><font-awesome-icon icon="chart-line" /> WxCC Dashboard</router-link>
    <router-link
      class="list-group-item list-group-item-action bg-light"
      to="/wxcc/queuegroups"  v-if="isWebexAuth && isWebexAdmin"
    ><font-awesome-icon icon="edit" /> WxCC Queue Groups</router-link>
    <router-link
      class="list-group-item list-group-item-action bg-light"
      to="/wxcc/admin"  v-if="isLoggedIn && isWebexAdmin"
    ><font-awesome-icon icon="tools" /> WxCC Admin</router-link>
    <!-- <router-link
      class="list-group-item list-group-item-action bg-light"
      to="/wxcc/agents"  v-if="isWebexAuth"
    ><font-awesome-icon icon="toolbox" /> Admin</router-link> -->
    <a
      v-if="banner"
      class="list-group-item list-group-item-action bg-warning"
      target="_blank"
      href="https://www.ateasystems.com/support/how-to-article/wxcc-dashboard-updates/">
      <font-awesome-icon icon="code-branch" /> {{banner}} <div style="font-size: 0.75rem;">{{bannerTime}}</div>
    </a>
    <b-form-select
      v-if="isWebexAdmin && hasMultipleCustomers"
      v-model="selectedCust"
      :options="options"></b-form-select>
    <!-- <b-dropdown
      v-if="hasMultipleCustomers"
       id="dropdown-1" text="Customer" class="m-md-2">
      <b-dropdown-item v-for="c in customerIds" :key="c" @click.prevent="setCustomer(c)">{{c}}</b-dropdown-item>

    </b-dropdown> -->
  </div>
</template>
<script>
import CodeService from '@/modules/wxcc/service/CodeService'

import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      redUri: process.env.NODE_ENV === 'development'
        ? 'http://localhost:8080/'
        : 'https://dashboard.wxcc.ateasystems.com/',
      selectedCust: '',
      options: [],
      banner: '',
      bannerTime: ''
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isWebexAuth: 'wxcc/isWebexAuth',
      wxccCustomerId: 'wxcc/wxccCustomerId',
      selectedWxccCustomerId: 'wxcc/selectedWxccCustomerId',
      roles: 'auth/roles'
    }),
    isWebexAdmin () {
      return this.roles.split(',').filter(e => e === 'wxcc_dashboard_admin').length === 1
    },
    hasMultipleCustomers () {
      console.log('auth user', this.$auth.user)
      if (this.$auth.user['http://cloud-portal/customerId']) {
        return this.$auth.user['http://cloud-portal/customerId'].split(',').length > 1
      }
      return false
    },
    customerIds () {
      return this.$auth.user['http://cloud-portal/customerId'].split(',')
    }
  },
  methods: {
    setCustomer (c) {
      this.$store.commit('wxcc/setWxccCustomerId', c)
    }
  },
  async mounted () {
    console.log('Mounted WxccNav')
    CodeService.getBanner().then(e => {
      this.banner = e.banner
      this.bannerTime = new Date(e.dateTime).toString()
      // this.bannerTime = new Date(e.dateTime).toLocaleString()
    }).catch(e => console.log(e))
    if (this.$auth.isAuthenticated() && this.$auth.user['http://cloud-portal/customerId']) {
      console.log('wxccnav mounted isauthenticated', this.$auth)
      const wxccCustomerId = this.$auth.user['http://cloud-portal/customerId'].split(',')[0]
      this.$store.commit('wxcc/setWxccCustomerId', wxccCustomerId)
      this.$store.commit('auth/setRoles', this.$auth.user['http://cloud-portal/roles'])
    }
    if (localStorage.user) {
      const user = JSON.parse(localStorage.user)
      console.log('WxccNav found user', user)
      this.$store.commit('wxcc/setWxccCustomerId', user['http://cloud-portal/customerId'].split(',')[0])
      this.$store.commit('wxcc/setIsWebexAuth', true)
      this.$store.commit('auth/setIsAuthenticated', true)
      this.$store.commit('auth/setRoles', user['http://cloud-portal/roles'])
    }
    if (this.$route.query.code) {
      this.message = 'Verifying code'
      this.code = this.$route.query.code
      console.dir(this.$store)
      CodeService.getWxTokenFromCode(this.code, this.redUri, this.$store, this.$router)
    }
    if (this.$auth.user['http://cloud-portal/customerId'].split(',').length > 1) {
      console.log('getting customers', 99)
      const c = await CodeService.getCustomers(this.$store, this.$auth.user['http://cloud-portal/customerId'])
      console.log('customers ', c)
      this.options = this.$auth.user['http://cloud-portal/customerId'].split(',').map(e => {
        console.log('mapping', e)
        const cid = c.find(el => el.customerid === e)
        return {
          value: e,
          text: cid ? cid.customername : 'unknown'
        }
      })
      console.log('this.options', this.options)
      if (this.selectedWxccCustomerId) {
        this.selectedCust = this.selectedWxccCustomerId
        this.$store.commit('wxcc/setWxccCustomerId', this.selectedWxccCustomerId)
      } else {
        this.selectedCust = this.wxccCustomerId
        this.$store.commit('wxcc/SET_SELECTED_CUSTOMER', this.wxccCustomerId)
      }
    }
    // if (localStorage.webex_api_access_token) {
    //   const now = new Date().getTime()
    //   if (localStorage.webex_api_expires_at > now) {
    //     this.$store.commit('wxcc/setIsWebexAuth', true)
    //     this.$store.commit('wxcc/setWebexToken', localStorage.webex_api_access_token)
    //   }
    // }
  },
  watch: {
    selectedCust: function (val) {
      console.log('WxccNav watch selectedCust')
      this.$store.commit('wxcc/setWxccCustomerId', val)
      this.$store.commit('wxcc/SET_SELECTED_CUSTOMER', val)
      this.$router.replace('/')
    },
    isLoggedIn: async function (val, v) {
      if (val) {
        console.log('watch isLoggedIn', val, v)
        const wxccCustomerId = this.$auth.user['http://cloud-portal/customerId'].split(',')[0]
        this.$store.commit('wxcc/setWxccCustomerId', wxccCustomerId)
        // if (wxccCustomerId) {
        //   await CodeService.getWxAccessTokens(this.$store)
        // }
        if (this.$auth.isAuthenticated()) {
          if (this.$auth.user['http://cloud-portal/customerId'] && this.$auth.user['http://cloud-portal/customerId'].split(',').length > 1) {
            this.$store.commit('wxcc/setWxccCustomerId', this.$auth.user['http://cloud-portal/customerId'].split(',')[0])
            console.log('getting customers', 142)
            const c = await CodeService.getCustomers(this.$store, this.$auth.user['http://cloud-portal/customerId'])
            console.log('customers ', c)
            this.options = this.$auth.user['http://cloud-portal/customerId'].split(',').map(e => {
              console.log('mapping', e)
              const cid = c.find(el => el.customerid === e)
              return {
                value: e,
                text: cid.customername
              }
            })
          }
          if (this.$auth.user['http://cloud-portal/customerId']) {
            const wxccCustomerId = this.$auth.user['http://cloud-portal/customerId'].split(',')[0]
            this.$store.commit('wxcc/setWxccCustomerId', wxccCustomerId)
            this.$store.commit('wxcc/SET_SELECTED_CUSTOMER', wxccCustomerId)
            this.selectedCust = wxccCustomerId
          }
        }
        if (localStorage.user) {
          const user = JSON.parse(localStorage.user)
          this.$store.commit('wxcc/setWxccCustomerId', user['http://cloud-portal/customerId'].split(',')[0])
          if (user['http://cloud-portal/customerId'].split(',').length > 1) {
            this.$store.commit('wxcc/setWxccCustomerId', user['http://cloud-portal/customerId'].split(',')[0])
          }
        }
        if (this.$route.query.code) {
          this.message = 'Verifying code'
          this.code = this.$route.query.code
          console.dir(this.$store)
          CodeService.getWxTokenFromCode(this.code, this.redUri, this.$store, this.$router)
        }
        this.$store.commit('wxcc/setIsWebexAuth', true)

        // if (localStorage.webex_api_access_token) {
        //   const now = new Date().getTime()
        //   if (localStorage.webex_api_expires_at > now) {
        //     this.$store.commit('wxcc/setIsWebexAuth', true)
        //     this.$store.commit('wxcc/setWebexToken', localStorage.webex_api_access_token)
        //   }
        // }
      }
    }
  }
}
</script>
