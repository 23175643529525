<template>
  <div class='callback'></div>
</template>

<script>
// import CodeService from '@/modules/wxcc/service/CodeService'
export default {
  name: 'authcallback',
  async mounted () {
    // await this.$auth.getTokenSilently({})
    this.$auth.handleAuthentication().then(async custObj => {
      console.log('authcallback', custObj)
      this.$store.commit('auth/setIsAuthenticated', true)
      // this.$store.commit('auth/setCustomerId', custObj.customerId)
      // this.$store.commit('auth/setZoomAccountId', custObj.zoomAccountId)
      this.$store.commit('auth/setRoles', custObj.roles)
      if (custObj.customerId) {
        // await CodeService.getWxAccessTokens(this.$store)
        this.$store.commit('wxcc/setIsWebexAuth', true)
      }
      this.$router.push({ name: 'Home' })
    })
  }
}
</script>
