const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/authcallback',
  //   name: 'authcallback',
  //   component: AuthCallback
  // },
  {
    path: '/wxcc/queues',
    name: 'wxccqueues',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/Queues.vue')
  },
  {
    path: '/wxcc/agents',
    name: 'wxccagents',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/Agents.vue')
  },
  {
    path: '/wxcc/admin',
    name: 'wxccadmin',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/WxccAdmin.vue')
  },
  {
    path: '/wxcc/dashboards',
    name: 'wxccdashboards',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/DashboardList.vue')
  },
  {
    path: '/wxcc/dashboard/:id',
    name: 'wxccdashboard',
    meta: {
      requiresAuth: true,
      skipPublicLink: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/Dashboard.vue')
  },
  {
    path: '/wxcc/dashboardedit/:id',
    name: 'wxccdashboardedit',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/DashboardEdit.vue')
  },
  {
    path: '/wxcc/dashboardeditlayout/:id',
    name: 'wxccdashboardeditlayout',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/DashboardEditLayout.vue')
  },
  {
    path: '/wxcc/queuegroups',
    name: 'wxccqueuegroups',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/WxccAdminEditQueueGroups.vue')
  },
  {
    path: '/wxcc/editqueuegroup/:id',
    name: 'wxccadmineditqueuegroup',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "unitreport" */ '../views/WxccAdminEditQueueGroup.vue')
  }
]

// const router = new VueRouter({
//   mode: 'history',
//   routes
// })

export default {
  routes
}
