export default {
  namespaced: true,
  state: {
    selectedCustomer: null,
    systems: [],
    allSystems: [],
    allCustomers: [],
    isLoggedIn: false,
    // customerId: null,
    roles: '',
    allAlerts: [],
    showNav: true,
    token: null
  },
  mutations: {
    setActiveCustomer (state, customer) {
      state.selectedCustomer = customer
    },
    setIsAuthenticated (state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn
    },
    // setCustomerId (state, customerId) {
    //   state.customerId = customerId
    // },
    setShowNav (state, showNav) {
      state.showNav = showNav
    },
    setRoles (state, roles) {
      state.roles = roles
    },
    setToken (state, token) {
      state.token = token
    }
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    roles: state => state.roles,
    // customerId: state => state.customerId,
    selectedCustomer: state => state.selectedCustomer,
    showNav: state => state.showNav,
    token: state => state.token
  }
}
