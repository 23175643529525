import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/modules/shared/views/Home.vue'
import AuthRoutes from '@/modules/auth/router/'
import WxccRoutes from '@/modules/wxcc/router/'
// import ZoomRoutes from '@/modules/zoom/router/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
AuthRoutes.routes.forEach(r => {
  router.addRoute(r)
})
WxccRoutes.routes.forEach(r => {
  router.addRoute(r)
})
// ZoomRoutes.routes.forEach(r => {
//   router.addRoute(r)
// })
router.beforeEach(async (to, from, next) => {
  // console.table(from)
  // console.table(to)
  if (to.meta.skipPublicLink && to.query.at) {
    next()
  } else {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    if (requiresAuth) {
      // console.log('router.beforeEach.requiresAuth', requiresAuth)
      var isAuthenticated = await router.app.$auth.isAuthenticated()
      console.log('router.beforeEach.isAuthenticated', isAuthenticated)
      if (!isAuthenticated) {
        // console.log('router isAuthenticated', isAuthenticated)
        router.app.$auth.renewTokens().then(() => {
          // console.log('router isAuthenticated - next()', isAuthenticated)
          next()
        }).catch(() => {
          // console.log('redirecting to home')
          router.app.$auth.logout()
          next('/')
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }
})
export default router
