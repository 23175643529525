export default {
  namespaced: true,
  state: {
    dbname: '',
    wxccCustomerId: null,
    selectedWxccCustomerId: null,
    isWebexAuth: false,
    isWebexAdmin: false,
    webexToken: '',
    webexRefreshToken: '',
    callsWaiting: 0,
    longestWaiting: '00:00',
    agents: [],
    queues: [],
    hourlyQueueStats: [],
    hourlyWaitStats: [],
    wsocket: null,
    isWsConnected: false,
    tickerworker: null,
    dashId: '',
    publicAccessToken: '',
    logo: '',
    agentSort: '',
    agentSort2: '',
    agentSortDirection: 'asc',
    agentSortDirection2: 'asc',
    queueSort: '',
    queueSortDirection: 'asc',
    emailSort: '',
    emailSortDirection: 'asc',
    chatSort: '',
    chatSortDirection: 'asc',
    hideNR: '',
    message: 'Hello, this is a test message for today. It`s going to be a very nice day with a lot of sunshine!',
    marqueeTime: 30,
    marqueeFontColor: 'red',
    queueTableColumns: [],
    emailTableColumns: [],
    chatTableColumns: [],
    showAgentCallsHandled: 'true',
    showAgentCallsPresented: 'false',
    showAgentExtension: 'false',
    showAgentTeam: 'false',
    showAgentATT: 'false',
    showAgentSkill: 'false',
    allowededitors: [],
    allowedsupervisors: [],
    queueGroups: [],
    colorfontinfo: '',
    colorbackgroundinfo: '',
    colorfontwarning: '',
    colorbackgroundwarning: '',
    colorfonturgent: '',
    colorbackgroundurgent: '',
    lwcolorfontinfo: '',
    lwcolorbackgroundinfo: '',
    lwcolorfontwarning: '',
    lwcolorbackgroundwarning: '',
    lwcolorfonturgent: '',
    lwcolorbackgroundurgent: '',
    goscolorfontinfo: '',
    goscolorbackgroundinfo: '',
    goscolorfontwarning: '',
    goscolorbackgroundwarning: '',
    goscolorfonturgent: '',
    goscolorbackgroundurgent: '',
    lastWsMessage: 0,
    lastStatsUpdate: 0,
    apiServer: 'https://api2.wxcc.ateasystems.com',
    queuenamealign: '',
    showQueueNameOnWrapup: false,
    showQueueNameOnTalking: false,
    cwvaluewarning: 0,
    cwvalueurgent: 2,
    cwvalueinfo: 5,
    lwvaluewarning: 0,
    lwvalueurgent: 30,
    lwvalueinfo: 60,
    gosvaluewarning: 90,
    gosvalueurgent: 85,
    gosvalueinfo: 70
  },
  mutations: {
    setWxccCustomerId (state, wxccCustomerId) {
      state.wxccCustomerId = wxccCustomerId
    },
    setIsWebexAuth (state, isWebexAuth) {
      state.isWebexAuth = isWebexAuth
    },
    setIsWebexAdmin (state, isWebexAdmin) {
      state.isWebexAdmin = isWebexAdmin
    },
    setWebexToken (state, webexToken) {
      state.webexToken = webexToken
    },
    setWebexRefreshToken (state, webexRefreshToken) {
      state.webexRefreshToken = webexRefreshToken
    },
    setCallsWaiting (state, callsWaiting) {
      state.callsWaiting = callsWaiting
    },
    setWebSocket (state, wsock) {
      state.wsocket = wsock
    },
    setWebSocketConnected (state, isWsConnected) {
      state.isWsConnected = isWsConnected
    },
    setAgents (state, agents) {
      state.agents = agents
    },
    setQueues (state, queues) {
      state.queues = queues
    },
    setHourlyQueues (state, hourlyQueueStats) {
      state.hourlyQueueStats = hourlyQueueStats
    },
    setHourlyWait (state, hourlyWaitStats) {
      state.hourlyWaitStats = hourlyWaitStats
    },
    setDashId (state, dashId) {
      state.dashId = dashId
    },
    setPublicAccessToken (state, publicAccessToken) {
      state.publicAccessToken = publicAccessToken
    },
    SAVE_WORKER (state, tickerworker) {
      state.tickerworker = tickerworker
    },
    INCREMENT_DURATION (state) {
      state.agents = state.agents.map(agt => {
        agt.duration = agt.duration + 1
        if ('holdtime' in agt) {
          agt.holdtime = agt.holdtime + 1
        }
        return agt
      })
      state.queues = state.queues.map(q => {
        if (q.longestwaiting > 0) {
          q.longestwaiting = q.longestwaiting + 1
        }
        return q
      })
    },
    PROCESS_WEBSOCKET_AGENTMESSAGE (state, val) {
      state.lastWsMessage = new Date().getTime()
      state.agents = state.agents.map(agt => {
        console.log('apply agent state change', val)
        // agt.displayName = `${val.firstName} ${val.lastName}`
        if (agt.id === val.agent) {
          console.log('found agent', agt)
          if (val.state === 'idle') {
            agt.state = 'Not Ready'
            agt.stateCode = 3
          }
          if (val.state === 'available') {
            agt.state = 'Ready'
            agt.reason = ''
            agt.stateCode = 4
          }
          if (val.state === 'offered') {
            agt.state = 'Ringing'
            agt.stateCode = 1
          }
          if (val.state === 'ringing') {
            agt.state = 'Ringing'
            agt.stateCode = 1
          }
          if (val.state === 'connected') {
            agt.state = 'Talking'
            agt.stateCode = 1
            if (val.queuename) {
              agt.queuename = val.queuename
            }
          }
          if (val.state === 'hold-done') {
            agt.state = 'Talking'
            agt.stateCode = 1
            agt.holdtime = 0
            if (val.queuename) {
              agt.queuename = val.queuename
            }
          }
          if (val.state === 'consulting') {
            agt.state = 'Talking'
            agt.stateCode = 1
          }
          if (val.state === 'consult-done') {
            agt.state = 'Talking'
            agt.stateCode = 1
          }
          if (val.state === 'on-hold') {
            agt.state = 'OnHold'
            agt.stateCode = 1
            agt.holdtime = 0
          }
          if (val.state === 'wrapup') {
            agt.state = 'Wrapup'
            agt.stateCode = 2
            if (val.queuename) {
              agt.queuename = val.queuename
            }
          }
          if (val.state === 'logged-out') {
            agt.state = 'Logout'
            agt.stateCode = 5
          }
          if (val.reason) agt.reason = val.reason
          if (val.team) agt.teamname = val.team
          agt.stateAndIdleCode = `${agt.state} ${agt.reason}`
          agt.stateCodeAndIdleCode = `${agt.stateCode} ${agt.reason}`
          if (val.state !== 'on-hold' && val.state !== 'hold-done') {
            agt.duration = 1
          }
        }
        console.log('storing agent state', agt)
        return agt
      })
    },
    PROCESS_WEBSOCKET_QUEUEMESSAGE (state, val) {
      console.log('PROCESS_WEBSOCKET_QUEUEMESSAGE', val)
      let found = false
      state.queues = state.queues.map(queue => {
        if (queue.id === val.queue) {
          console.log('PROCESS_WEBSOCKET_QUEUEMESSAGE found queueId', queue)
          if ((val.taskType === 'parked' && val.previousType === 'new') || (val.taskType === 'connect' && val.previousType === 'new')) {
            console.log('PROCESS_WEBSOCKET_QUEUEMESSAGE increase callswaiting for ', queue.id)
            if (queue.callswaiting === 0) {
              queue.longestwaiting = 1
            }
            queue.callswaiting++
            found = true
          } else if (val.taskType === 'connected' || (val.taskType === 'ended' && 'previousType' in val && val.previousType !== 'connected')) {
            console.log('PROCESS_WEBSOCKET_QUEUEMESSAGE decrease callswaiting for ', queue.id)
            if (queue.callswaiting > 0) queue.callswaiting--
            if (queue.callswaiting === 0) queue.longestwaiting = 0
            found = true
          }
        }
        return queue
      })
      if (found) state.lastWsMessage = new Date().getTime()
    },
    SET_DASHBOARD (state, val) {
      if ('name' in val) {
        state.dbname = val.name
      }
      if ('logo' in val) {
        state.logo = val.logo
      }
      if ('agentsort' in val) {
        state.agentSort = val.agentsort
      }
      if ('agentsortdirection' in val) {
        state.agentSortDirection = val.agentsortdirection
      }
      if ('agentsort2' in val) {
        state.agentSort2 = val.agentsort2
      }
      if ('agentsortdirection2' in val) {
        state.agentSortDirection2 = val.agentsortdirection2
      }
      if ('queuesort' in val) {
        state.queueSort = val.queuesort
      }
      if ('queuesortdirection' in val) {
        state.queueSortDirection = val.queuesortdirection
      }
      if ('emailsort' in val) {
        state.emailSort = val.emailsort
      }
      if ('emailsortdirection' in val) {
        state.emailSortDirection = val.emailsortdirection
      }
      if ('chatsort' in val) {
        state.chatSort = val.chatsort
      }
      if ('chatsortdirection' in val) {
        state.chatSortDirection = val.chatsortdirection
      }
      if ('hidenr' in val) {
        state.hideNR = val.hidenr
      }
      if ('message' in val) {
        state.message = val.message
      }
      if ('marqueetime' in val) {
        state.marqueeTime = val.marqueetime
      }
      if ('marqueefontcolor' in val) {
        state.marqueeFontColor = val.marqueefontcolor
      }
      if ('queuetablecolumns' in val) {
        state.queueTableColumns = val.queuetablecolumns
      }
      if ('emailtablecolumns' in val) {
        state.emailTableColumns = val.emailtablecolumns
      }
      if ('chattablecolumns' in val) {
        state.chatTableColumns = val.chattablecolumns
      }
      if ('showagentcallshandled' in val) {
        state.showAgentCallsHandled = val.showagentcallshandled
      }
      if ('showagentcallspresented' in val) {
        state.showAgentCallsPresented = val.showagentcallspresented
      }
      if ('showagentextension' in val) {
        state.showAgentExtension = val.showagentextension
      }
      if ('showagentteam' in val) {
        state.showAgentTeam = val.showagentteam
      }
      if ('showagentatt' in val) {
        state.showAgentATT = val.showagentatt
      }
      if ('showagentskill' in val) {
        state.showAgentSkill = val.showagentskill
      }
      if ('allowededitors' in val) {
        state.allowededitors = val.allowededitors
      }
      if ('allowedsupervisors' in val) {
        state.allowedsupervisors = val.allowedsupervisors
      }
      if ('gridlayout' in val && val.gridlayout !== '') {
        if (!localStorage.getItem('atea-grid-layout-' + state.dashId)) {
          localStorage.setItem('atea-grid-layout-' + state.dashId, atob(val.gridlayout))
        }
      }
      if ('gridsizes' in val && val.gridsizes !== '') {
        if (!localStorage.getItem('atea-grid-sizes-' + state.dashId)) {
          localStorage.setItem('atea-grid-sizes-' + state.dashId, atob(val.gridsizes))
          location.reload()
        }
      }
      if ('colorfontinfo' in val) {
        state.colorfontinfo = val.colorfontinfo
      }
      if ('colorbackgroundinfo' in val) {
        state.colorbackgroundinfo = val.colorbackgroundinfo
      }
      if ('colorfontwarning' in val) {
        state.colorfontwarning = val.colorfontwarning
      }
      if ('colorbackgroundwarning' in val) {
        state.colorbackgroundwarning = val.colorbackgroundwarning
      }
      if ('colorfonturgent' in val) {
        state.colorfonturgent = val.colorfonturgent
      }
      if ('colorbackgroundurgent' in val) {
        state.colorbackgroundurgent = val.colorbackgroundurgent
      }
      if ('lwcolorfontinfo' in val) {
        state.lwcolorfontinfo = val.lwcolorfontinfo
      }
      if ('lwcolorbackgroundinfo' in val) {
        state.lwcolorbackgroundinfo = val.lwcolorbackgroundinfo
      }
      if ('lwcolorfontwarning' in val) {
        state.lwcolorfontwarning = val.lwcolorfontwarning
      }
      if ('lwcolorbackgroundwarning' in val) {
        state.lwcolorbackgroundwarning = val.lwcolorbackgroundwarning
      }
      if ('lwcolorfonturgent' in val) {
        state.lwcolorfonturgent = val.lwcolorfonturgent
      }
      if ('lwcolorbackgroundurgent' in val) {
        state.lwcolorbackgroundurgent = val.lwcolorbackgroundurgent
      }
      if ('goscolorfontinfo' in val) {
        state.goscolorfontinfo = val.goscolorfontinfo
      }
      if ('goscolorbackgroundinfo' in val) {
        state.goscolorbackgroundinfo = val.goscolorbackgroundinfo
      }
      if ('goscolorfontwarning' in val) {
        state.goscolorfontwarning = val.goscolorfontwarning
      }
      if ('goscolorbackgroundwarning' in val) {
        state.goscolorbackgroundwarning = val.goscolorbackgroundwarning
      }
      if ('goscolorfonturgent' in val) {
        state.goscolorfonturgent = val.goscolorfonturgent
      }
      if ('goscolorbackgroundurgent' in val) {
        state.goscolorbackgroundurgent = val.goscolorbackgroundurgent
      }
      if ('queuenamealign' in val) {
        state.queuenamealign = val.queuenamealign
      }
      if ('showqueuenameonwrapup' in val) {
        state.showQueueNameOnWrapup = val.showqueuenameonwrapup
      }
      if ('showqueuenameontalking' in val) {
        state.showQueueNameOnTalking = val.showqueuenameontalking
      }
      if ('cwvaluewarning' in val) {
        state.cwvaluewarning = val.cwvaluewarning
      }
      if ('cwvalueurgent' in val) {
        state.cwvalueurgent = val.cwvalueurgent
      }
      if ('cwvalueinfo' in val) {
        state.cwvalueinfo = val.cwvalueinfo
      }
      if ('lwvaluewarning' in val) {
        state.lwvaluewarning = val.lwvaluewarning
      }
      if ('lwvalueurgent' in val) {
        state.lwvalueurgent = val.lwvalueurgent
      }
      if ('lwvalueinfo' in val) {
        state.lwvalueinfo = val.lwvalueinfo
      }
      if ('gosvaluewarning' in val) {
        state.gosvaluewarning = val.gosvaluewarning
      }
      if ('gosvalueurgent' in val) {
        state.gosvalueurgent = val.gosvalueurgent
      }
      if ('gosvalueinfo' in val) {
        state.gosvalueinfo = val.gosvalueinfo
      }
    },
    SET_QUEUE_GROUPS (state, val) {
      state.queueGroups = val
      // TODO
    },
    SET_SELECTED_CUSTOMER (state, val) {
      state.selectedWxccCustomerId = val
    },
    setLastStatsUpdate (state, val) {
      state.lastStatsUpdate = val
    },
    setApiServer (state, val) {
      state.apiServer = val
    }
  },
  actions: {
    INIT (context) {
      var blob = new Blob([
        document.querySelector('#tickerworker').textContent
      ], { type: 'text/javascript' })
      var tickerworker = new Worker(window.URL.createObjectURL(blob))
      tickerworker.onmessage = () => {
        context.commit('INCREMENT_DURATION')
      }
      context.commit('SAVE_WORKER', tickerworker)
      context.state.tickerworker.postMessage('start')
    },
    SAVE_WEBSOCKET (context, val) {
      console.log('saving websocket', val)
      context.commit('setWebSocket', val)
    },
    PROCESS_WEBSOCKET_MESSAGE (context, val) {
      console.log('WSMSG', val)
    },
    STOP_WEBSOCKET (context) {
      // console.log('closing websocket')
      // console.dir(context.state.wsocket)
      context.state.wsocket.close()
      // context.state.tickerworker.postMessage('stop')
    },
    STOP_WORKER (context) {
      context.state.tickerworker.postMessage('stop')
      context.state.tickerworker.terminate()
      delete context.state.tickerworker
    }
  },
  getters: {
    dbname: state => state.dbname,
    wxccCustomerId: state => state.wxccCustomerId,
    selectedWxccCustomerId: state => state.selectedWxccCustomerId,
    isWebexAuth: state => state.isWebexAuth,
    isWebexAdmin: state => state.isWebexAdmin,
    webexToken: state => state.webexToken,
    callsWaiting: state => state.callsWaiting,
    longestWaiting: state => state.longestWaiting,
    isWsConnected: state => state.isWsConnected,
    agents: state => state.agents,
    queues: state => state.queues.filter(e => e.channeltype === 'TELEPHONY'),
    emailqueues: state => state.queues.filter(e => e.channeltype === 'EMAIL'),
    chatqueues: state => state.queues.filter(e => e.channeltype === 'CHAT'),
    hourlyQueueStats: state => state.hourlyQueueStats,
    hourlyWaitStats: state => state.hourlyWaitStats,
    dashId: state => state.dashId,
    publicAccessToken: state => state.publicAccessToken,
    logo: state => state.logo,
    agentSort: state => state.agentSort,
    agentSort2: state => state.agentSort2,
    agentSortDirection: state => state.agentSortDirection,
    agentSortDirection2: state => state.agentSortDirection2,
    queueSort: state => state.queueSort,
    queueSortDirection: state => state.queueSortDirection,
    queueNameAlign: state => state.queuenamealign,
    emailSort: state => state.emailSort,
    emailSortDirection: state => state.emailSortDirection,
    chatSort: state => state.chatSort,
    chatSortDirection: state => state.chatSortDirection,
    hideNR: state => state.hideNR,
    message: state => state.message,
    marqueeTime: state => state.marqueeTime,
    marqueeFontColor: state => state.marqueeFontColor,
    queueTableColumns: state => state.queueTableColumns,
    emailTableColumns: state => state.emailTableColumns,
    chatTableColumns: state => state.chatTableColumns,
    showAgentCallsHandled: state => state.showAgentCallsHandled,
    showAgentCallsPresented: state => state.showAgentCallsPresented,
    showAgentExtension: state => state.showAgentExtension,
    showAgentTeam: state => state.showAgentTeam,
    showAgentATT: state => state.showAgentATT,
    showAgentSkill: state => state.showAgentSkill,
    allowedEditors: state => state.allowededitors,
    allowedSupervisors: state => state.allowedsupervisors,
    queuesSUM: state => {
      const summedQ = []
      const queuesToReturn = []
      // console.log('state.queueGroups ', state.queueGroups)
      for (const queueGroup of state.queueGroups) {
        // console.log('SUM ', queueGroup)
        let totalTasksoffered = 0
        const queueStats = {
          availableagents: 'n/a',
          avgenqueuetime: 0,
          avghandletime: 0,
          callswaiting: 0,
          channeltype: 'TELEPHONY',
          connectedagents: 0,
          gos: 0,
          id: queueGroup.id,
          loggedinagents: 0,
          longestwaiting: 0,
          name: queueGroup.name,
          routingtype: 'LONGEST_AVAILABLE_AGENT',
          tasksabandoned: 0,
          tasksshort: 0,
          taskshandled: 0,
          tasksoffered: 0,
          tasksrejected: 0,
          taskstotal: 0
        }

        for (const qgID of queueGroup.queueids) {
          // console.log('qgID', qgID)
          // console.log('queues', state.queues)
          const csq = state.queues.find(e => e.id === qgID)
          if (csq) {
            // console.log('found csq', csq)
            summedQ.push(csq.id)
            totalTasksoffered = totalTasksoffered + csq.tasksoffered
            queueStats.callswaiting = queueStats.callswaiting + csq.callswaiting
            queueStats.tasksoffered = queueStats.tasksoffered + csq.tasksoffered
            queueStats.taskshandled = queueStats.taskshandled + csq.taskshandled
            queueStats.tasksabandoned = queueStats.tasksabandoned + csq.tasksabandoned
            queueStats.tasksshort = queueStats.tasksshort + csq.tasksshort
            queueStats.tasksrejected = queueStats.tasksrejected + csq.tasksrejected
            queueStats.taskstotal = queueStats.taskstotal + Number(csq.taskstotal)
            queueStats.connectedagents = queueStats.connectedagents + csq.connectedagents
            if (csq.loggedinagents > queueStats.loggedinagents) {
              queueStats.loggedinagents = csq.loggedinagents
            }
            if (csq.longestwaiting > queueStats.longestwaiting) queueStats.longestwaiting = csq.longestwaiting
            if (csq.tasksoffered > 0) {
              queueStats.avghandletime = queueStats.avghandletime + (csq.avghandletime * csq.tasksoffered)
              queueStats.avgenqueuetime = queueStats.avgenqueuetime + (csq.avgenqueuetime * csq.tasksoffered)
              queueStats.gos = queueStats.gos + (csq.gos * csq.tasksoffered)
            }
          }
        }
        if (totalTasksoffered > 0) {
          queueStats.avghandletime = queueStats.avghandletime / totalTasksoffered
          queueStats.avgenqueuetime = queueStats.avgenqueuetime / totalTasksoffered
          queueStats.gos = Math.round(queueStats.gos / totalTasksoffered)
        }
        queuesToReturn.push(queueStats)
      }
      for (const q of state.queues.filter(e => e.channeltype === 'TELEPHONY')) {
        if (!summedQ.includes(q.id)) queuesToReturn.push(q)
      }
      return queuesToReturn
    },
    colorfontinfo: state => state.colorfontinfo,
    colorbackgroundinfo: state => state.colorbackgroundinfo,
    colorfontwarning: state => state.colorfontwarning,
    colorbackgroundwarning: state => state.colorbackgroundwarning,
    colorfonturgent: state => state.colorfonturgent,
    colorbackgroundurgent: state => state.colorbackgroundurgent,
    lwcolorfontinfo: state => state.lwcolorfontinfo,
    lwcolorbackgroundinfo: state => state.lwcolorbackgroundinfo,
    lwcolorfontwarning: state => state.lwcolorfontwarning,
    lwcolorbackgroundwarning: state => state.lwcolorbackgroundwarning,
    lwcolorfonturgent: state => state.lwcolorfonturgent,
    lwcolorbackgroundurgent: state => state.lwcolorbackgroundurgent,
    goscolorfontinfo: state => state.goscolorfontinfo,
    goscolorbackgroundinfo: state => state.goscolorbackgroundinfo,
    goscolorfontwarning: state => state.goscolorfontwarning,
    goscolorbackgroundwarning: state => state.goscolorbackgroundwarning,
    goscolorfonturgent: state => state.goscolorfonturgent,
    goscolorbackgroundurgent: state => state.goscolorbackgroundurgent,
    lastWsMessage: state => state.lastWsMessage,
    lastStatsUpdate: state => state.lastStatsUpdate,
    apiServer: state => state.apiServer,
    showQueueNameOnWrapup: state => state.showQueueNameOnWrapup,
    showQueueNameOnTalking: state => state.showQueueNameOnTalking,
    cwvaluewarning: state => state.cwvaluewarning,
    cwvalueurgent: state => state.cwvalueurgent,
    cwvalueinfo: state => state.cwvalueinfo,
    lwvaluewarning: state => state.lwvaluewarning,
    lwvalueurgent: state => state.lwvalueurgent,
    lwvalueinfo: state => state.lwvalueinfo,
    gosvaluewarning: state => state.gosvaluewarning,
    gosvalueurgent: state => state.gosvalueurgent,
    gosvalueinfo: state => state.gosvalueinfo
  }
}
