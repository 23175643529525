import axios from 'axios'

export default {
  getWxTokenFromCode (code, redUri, store, router) {
    axios
      .post(
          `/uts/code/${store.getters['wxcc/wxccCustomerId']}`,
          {
            code: code,
            redirect_uri: redUri
          },
          {
            // baseURL: process.env.VUE_APP_HEROKU_URL,
            baseURL: `${store.getters['wxcc/apiServer']}`,
            headers: {
              'WxCC-TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WxCC-CustomerIds': store.getters['wxcc/wxccCustomerId'],
              Authorization: 'Bearer ' + localStorage.access_token,
              'Content-Type': 'application/json'
            }
          }
      )
      .then(res => {
        console.table(res.data)
        if (res.data.access_token) {
          console.log('RECIEVED TOKEN!!', res.data.access_token)
          // const now = new Date().getTime()
          localStorage.webex_api_access_token = res.data.access_token
          // localStorage.webex_api_expires_in = res.data.expires_in
          localStorage.webex_api_expires_at = res.data.access_token_expiry * 1000
          localStorage.webex_api_refresh_token = res.data.refresh_token
          // localStorage.webex_api_refresh_token_expires_in =
          //     res.data.refresh_token_expires_in
          localStorage.webex_api_refresh_token_expires_at =
              res.data.refresh_token_expiry * 1000
          store.commit('wxcc/setIsWebexAuth', true)
          store.commit('wxcc/setWebexToken', localStorage.webex_api_access_token)
          store.commit('wxcc/setWebexRefreshToken', localStorage.webex_api_refresh_token)
          router.replace('/')
        }
      })
      .catch(err => {
        this.message = 'Error getting access token ' + err
      })
  },
  getWxAccessTokens (store) {
    return axios
      .get(
          `/uts/tokens/${store.getters['wxcc/wxccCustomerId']}`,
          {
            // baseURL: process.env.VUE_APP_HEROKU_URL,
            baseURL: `${store.getters['wxcc/apiServer']}`,
            headers: {
              'WxCC-TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WxCC-CustomerIds': store.getters['wxcc/wxccCustomerId'],
              Authorization: 'Bearer ' + localStorage.access_token,
              'Content-Type': 'application/json'
            }
          }
      )
      .then(res => {
        console.table(res.data)
        if (res.data.access_token) {
          console.log('RECIEVED TOKEN!!', res.data.access_token)
          const now = new Date().getTime()
          localStorage.webex_api_access_token = res.data.access_token
          localStorage.webex_api_expires_in = res.data.expires_in
          localStorage.webex_api_expires_at = res.data.expires_in + now
          localStorage.webex_api_refresh_token = res.data.refresh_token
          localStorage.webex_api_refresh_token_expires_in =
              res.data.refresh_token_expires_in
          localStorage.webex_api_refresh_token_expires_at =
              res.data.refresh_token_expires_in + now
          store.commit('wxcc/setIsWebexAuth', true)
          store.commit('wxcc/setWebexToken', localStorage.webex_api_access_token)
          store.commit('wxcc/setWebexRefreshToken', localStorage.webex_api_refresh_token)
        }
      })
      .catch(err => {
        this.message = 'Error getting access token ' + err
      })
  },
  getClientId (store) {
    return axios
      .get(
          `/uts/clientid/${store.getters['wxcc/wxccCustomerId']}`,
          {
            // baseURL: process.env.VUE_APP_HEROKU_URL,
            baseURL: `${store.getters['wxcc/apiServer']}`,
            headers: {
              'WxCC-TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WxCC-CustomerIds': store.getters['wxcc/wxccCustomerId'],
              Authorization: 'Bearer ' + localStorage.access_token,
              'Content-Type': 'application/json'
            }
          }
      ).then(res => {
        return res.data
      })
  },
  getCustomers (store, cids) {
    return axios
      .get(`/api/admin/customers?cids=${cids}`,
        {
          // baseURL: process.env.VUE_APP_HEROKU_URL,
          baseURL: `${store.getters['wxcc/apiServer']}`,
          headers: {
            'WxCC-TimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            Authorization: 'Bearer ' + localStorage.access_token,
            'Content-Type': 'application/json'
          }
        }
      ).then(res => {
        return res.data
      })
  },
  getApiServer (type, id) {
    console.log(`getApiServer type:${type}, id:${id}`)
    return axios
      .get(`/66a611bc-28ba-4722-86fb-739e849ca2c1?${type}=${id}`,
        {
          baseURL: process.env.VUE_APP_API_SERVER
        }
      ).then(res => {
        return res.data
      })
  },
  getBanner () {
    console.log('getBanner')
    return axios
      .get('/66a611bc-28ba-4722-86fb-739e849ca2c1?ban=true',
        {
          baseURL: process.env.VUE_APP_API_SERVER
        }
      ).then(res => {
        return res.data
      })
  }
}
