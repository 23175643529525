<template>
  <div id='page-content-wrapper text-left'>
    <div id='page-content' class v-if='!isLoggedInAuth0'>
      Please Login
    </div>
    <div id='page-content' class v-if='isLoggedInAuth0'>
      <b-card
        header='Webex Authentication'
        style='max-width: 54.3rem;'
        class='mb-2'
        ref='formContainer5'
        vv-if='!isWebexAuth && customerId && !codeVerifyInProgress'
        v-if='!isWebexAuth && customerId && !codeVerifyInProgress'
      >
        <b-card-body>
          <!-- <b-form @submit='onSubmit' @reset='onReset' >
            <p>
              {{ message }}
            </p>
            <b-button type='submit' variant='primary'
              >Request Access Token</b-button
            ><br />
            This will redirect to Cisco Webex, please login with an account that
            has access to Contact Centre in the Control Hub and allow the requested
            scope if asked for (this scope request is on the first login
            only).<br />You will then be redirected back here.
          </b-form> -->
          <div v-if='isWebexAuth'>
            <p>
              Access Token Received Successfully.
            </p>
          </div>
          <div v-else>
            <p>
              No Access Token Received.
            </p>
          </div>
        </b-card-body>
      </b-card>
      <b-card
        header='Webex Tokens'
        style='max-width: 54.3rem;'
        class='mb-2'
        ref='formContainer5'
        v-if='!isWebexAuth && this.$store.getters.customerId && !codeVerifyInProgress'
      >
        <b-card-body>
            <p>
              {{ message }}
            </p>
            <b-button @click="getTokens" variant='primary'
              >Refresh Access Token</b-button
            ><br />
          <div v-if='isWebexAuth'>
            <p>
              Access Token Received Successfully.
            </p>
          </div>
        </b-card-body>
      </b-card>
      <b-card
        header='Authorization'
        style='max-width: 54.3rem;'
        class='mb-2'
        ref='formContainer5'
        v-if='!isWebexAuth && !customerId && !zoomAccountId'
      >
        <b-card-body>
          <p>
            There is no Customer assigned to this account, please contact Atea Support support@ateasystems.com
          </p>
        </b-card-body>
      </b-card>
      <b-card
        header='Welcome'
        style='max-width: 54.3rem;'
        class='mb-2'
        ref='formContainer5'
        v-if='zoomAccountId'
      >
        <b-card-body>
          <p>
            Welcome to Atea Cloud Reporting & Analysis<br/>
            Zoom VMS has moved, see <a href="https://zoom.katene.dev/vms/">Link</a>
          </p>
        </b-card-body>
      </b-card>
      <div v-if='codeVerifyInProgress && !isWebexAuth'>Code received from Webex Login, getting Webex API token please wait.</div>
      <div v-if='isWebexAuth'>You now have a valid Webex API token</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import WebexService from '@/modules/wxcc/service/WebexService'
import CodeService from '@/modules/wxcc/service/CodeService'
export default {
  name: 'Home',
  data: function () {
    return {
      message: '',
      codeVerifyInProgress: true
    }
  },
  async mounted () {
    this.$store.commit('auth/setShowNav', true)
    if (this.$route.query.code) {
      this.codeVerifyInProgress = true
    } else {
      this.codeVerifyInProgress = false
    }
    if (this.isWebexAuth && this.customerId) {
      // console.log('Home - router push dashboards')
      this.$router.push({ path: '/wxcc/dashboards' })
    }
  },
  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      window.location.href = WebexService.getAuthUrl()
    },
    onReset (evt) {
      evt.preventDefault()
    },
    getTokens (evt) {
      CodeService.getWxAccessTokens(this.$store)
    }
  },
  components: {
  },
  computed: {
    ...mapGetters({
      isLoggedInAuth0: 'auth/isLoggedIn',
      isWebexAuth: 'wxcc/isWebexAuth',
      showNav: 'auth/showNav',
      customerId: 'wxcc/wxccCustomerId',
      zoomAccountId: 'zoom/zoomAccountId'
    }),
    isAuthenticated () {
      return this.$auth.isAuthenticated()
    }
  }
}
</script>
