import Vue from 'vue'
import Vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'

import Auth from '@/modules/auth/store'
import Wxcc from '@/modules/wxcc/store'
import Zoom from '@/modules/zoom/store'
import Shared from '@/modules/shared/store'

Vue.use(Vuex)
Vue.use(BootstrapVue)

export default new Vuex.Store({
  modules: {
    auth: Auth,
    wxcc: Wxcc,
    zoom: Zoom,
    global: Shared
  }
})
