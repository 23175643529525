import auth0 from 'auth0-js'
import Vue from 'vue'

const webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientID: process.env.VUE_APP_AUTH0_CLIENTID,
  redirectUri: process.env.VUE_APP_AUTH0_LOGIN || 'http://localhost:8080/authcallback',
  audience: 'https://' + process.env.VUE_APP_AUTH0_DOMAIN + '/api/v2/',
  responseType: 'token id_token',
  scope: 'openid profile' // define the scopes you want to use
})

const auth = new Vue({
  data () {
    return {
      expires: 0,
      token: '',
      accessToken: '',
      user: {},
      webWorker: null,
      store: null
    }
  },
  computed: {
    expiresAt: {
      get: function () {
        return this.expires
      },
      set: function (expiresIn) {
        this.expires = expiresIn * 1000 + new Date().getTime()
      }
    }
  },
  methods: {
    setStore (store) {
      console.log('setStore', store)
      this.store = store
      this.initWebWorker()
      this.restoreStorage()
    },
    login () {
      webAuth.authorize()
    },
    logout () {
      return new Promise(() => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('id_token')
        localStorage.removeItem('expires_at')
        localStorage.removeItem('user')
        localStorage.removeItem('webex_api_access_token')
        localStorage.removeItem('webex_api_expires_in')
        localStorage.removeItem('webex_api_expires_at')
        localStorage.removeItem('webex_api_refresh_token')
        localStorage.removeItem('webex_api_refresh_token_expires_in')
        localStorage.removeItem('webex_api_refresh_token_expires_at')
        webAuth.logout({
          returnTo:
            process.env.VUE_APP_AUTH0_LOGOUT || 'http://localhost:8080/', // Allowed logout URL listed in dashboard
          clientID: process.env.VUE_APP_AUTH0_CLIENTID // Your client ID
        })
      })
    },
    async isAuthenticated () {
      // await auth0.getTokenSilently()
      var notExpired = new Date().getTime() < this.expiresAt
      return notExpired
    },
    localLogin (authResult) {
      this.expiresAt = authResult.expiresIn
      this.accessToken = authResult.accessToken
      this.token = authResult.idToken
      this.user = authResult.idTokenPayload
      this.saveToStorage(authResult.accessToken, authResult.idToken, this.expiresAt, authResult.idTokenPayload)
      console.log('localLogin setIsAuthenticated', true)
      // this.$store.commit('auth/setIsAuthenticated', true)
      console.log('localLogin setRoles', authResult.idTokenPayload['http://cloud-portal/roles'])
      // this.$store.commit('auth/setRoles', authResult.idTokenPayload['http://cloud-portal/roles'])
      var expiresAt = this.expiresAt
      var delay = expiresAt - Date.now()
      if (delay > 0) {
        delay = delay - (10 * 60 * 1000)
        // delay = 10000
        console.log('--- SETTING TIMEOUT FOR TOKEN RENEW IN:' + delay / 1000 / 60 + 'min')
        this.webWorker.postMessage(delay)
        // this.renewalTimer = window.setTimeout(
        //   this.renewTokens,
        //   delay
        // )
      }
    },
    handleAuthentication () {
      return new Promise((resolve, reject) => {
        webAuth.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.localLogin(authResult)
            resolve({
              // customerId: authResult.idTokenPayload['http://atea-wxcc/customerId'],
              // zoomAccountId: authResult.idTokenPayload['http://atea-wxcc/zoomAccountId'],
              roles: authResult.idTokenPayload['http://cloud-portal/roles']
            })
          } else if (err) {
            this.logout()
            reject(err)
          } else {
            reject(new Error('error'))
          }
        })
      })
    },
    renewTokens () {
      return new Promise((resolve, reject) => {
        // if (localStorage.getItem('expires_at') === null) {
        //   reject(new Error('Not logged in'))
        // }
        console.log('checkSession')
        webAuth.checkSession({}, (err, authResult) => {
          if (err) {
            reject(err)
          } else {
            console.log('--renewTokens result', authResult)
            this.localLogin(authResult)
            resolve(authResult)
          }
        })
      })
    },
    restoreStorage () {
      if (localStorage.getItem('access_token')) {
        this.accessToken = localStorage.getItem('access_token')
        this.store.commit('auth/setToken', this.accessToken)
      }
      if (localStorage.getItem('id_token')) this.token = localStorage.getItem('id_token')
      if (localStorage.getItem('expires_at')) this.expires = localStorage.getItem('expires_at')
      if (localStorage.getItem('user')) this.user = JSON.parse(localStorage.getItem('user'))
    },
    saveToStorage (accessToken, token, expires, user) {
      this.store.commit('auth/setToken', accessToken)
      localStorage.setItem('access_token', accessToken)
      localStorage.setItem('id_token', token)
      localStorage.setItem('expires_at', expires)
      localStorage.setItem('user', JSON.stringify(user))
    },
    initWebWorker () {
      console.log('INIT webworker')
      const ww = `function (e) {
        console.log('WEBWORKER FUNC ', parseInt(e.data, 10))
        self.setTimeout(function () {
          self.postMessage('renewToken')
        }, parseInt(e.data, 10))
      }`
      var blob
      try {
        blob = new Blob([`self.onmessage=${ww}`], { type: 'application/javascript' })
      } catch (e) { // Backwards-compatibility
        window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder
        blob = new window.BlobBuilder()
        blob.append(`self.onmessage=${ww}`)
        blob = blob.getBlob()
      }
      const worker = new Worker(
        window.URL.createObjectURL(blob)
      )
      worker.onmessage = ({ data }) => {
        if (data === 'renewToken') {
          console.log('received webworker event ', data)
          this.renewTokens()
        }
      }
      console.log('initialized webworker', worker)
      this.webWorker = worker
    }
  },
  created () {
    // this.initWebWorker()
    // this.restoreStorage()
  }
})

export default {
  install: function (Vue) {
    Vue.prototype.$auth = auth
  }
}
