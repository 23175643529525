// import axios from 'axios'

export default {
  getAuthUrl (clientID) {
    // const scope = 'spark-admin:devices_read spark:xapi_statuses spark-admin:people_read spark:xapi_commands'
    const scope = 'cjp:config_write cjp:config_read'
    // const scope ='Identity:SCIM Identity:Organization cjp:config_read spark:people_read cjp-analyzer:read cjp:config_write',

    return `https://webexapis.com/v1/authorize?response_type=code&client_id=${
      clientID
    }&redirect_uri=${encodeURIComponent(
      process.env.NODE_ENV === 'development'
          ? 'http://localhost:8080/'
          : 'https://dashboard.wxcc.ateasystems.com/'
    )}&scope=${
      encodeURIComponent(scope)
    }&state=AteaWxCC`
  }//,
  // getQueues (accessToken) {
  //   return axios
  //     .get('https://webexapis.com/v1/devices', {
  //       headers: {
  //         authorization: 'Bearer ' + accessToken
  //       }
  //     })
  //     .then(result => {
  //       return result.data.items
  //     })
  // },
  // getAgents (accessToken) {
  //   return axios
  //     .get('https://webexapis.com/v1/people', {
  //       headers: {
  //         authorization: 'Bearer ' + accessToken
  //       }
  //     })
  //     .then(result => {
  //       return result.data.items
  //     })
  // }
}
