import AuthCallback from '@/modules/auth/views/AuthCallback'

const routes = [
  {
    path: '/authcallback',
    name: 'authcallback',
    component: AuthCallback
  }
]

export default {
  routes
}
