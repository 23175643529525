import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import auth from '@/modules/auth/service/AuthService'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faExclamationTriangle,
  faSignInAlt,
  faSignOutAlt,
  faArrowsAlt,
  faCopy,
  faEdit,
  faEye,
  faSave,
  faUser,
  faTable,
  faTrashAlt,
  faToolbox,
  faChartLine,
  faSearch,
  faTimes,
  faArrowAltCircleLeft,
  faRetweet,
  faTools,
  faTimesCircle,
  faCodeBranch
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faExclamationTriangle,
  faSignInAlt,
  faSignOutAlt,
  faArrowsAlt,
  faCopy,
  faEdit,
  faEye,
  faSave,
  faUser,
  faTable,
  faTrashAlt,
  faToolbox,
  faChartLine,
  faSearch,
  faTimes,
  faArrowAltCircleLeft,
  faRetweet,
  faTools,
  faTimesCircle,
  faCodeBranch
)

Vue.use(auth)
Vue.use(BootstrapVue)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(Loading)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
