<template>
  <div id='app'>
    <div class='d-flex' id='wrapper'>
      <div class='bg-light border-right' id='sidebar-wrapper' v-if="showNav">
        <div class='sidebar-heading border-bottom' style='height: 60px;'>
          <img alt='Atea Logo' src='./assets/logo.png' />&nbsp;&nbsp;Reporting
        </div>
        <div class='list-group list-group-flush'>
          <a
            href='#'
            class='list-group-item list-group-item-action bg-light'
            @click.prevent='login'
            v-if='!isLoggedInAuth0'
            ><font-awesome-icon icon='sign-in-alt' /> Login</a
          >
          <!-- <ZoomNav :auth="this.$auth"/> -->
          <WxccNav v-if="isMounted && isCreated" :auth="this.$auth" :apiServer="apiServer"/>
          <a
            href='#'
            class='list-group-item list-group-item-action bg-light'
            @click.prevent='logout'
            v-if='isLoggedInAuth0'
            ><font-awesome-icon icon='sign-out-alt'/> Logout<br /><img
              style='max-width: 20px'
              :src='userAvatar'/>
            {{ userNickname }}<br
          /></a>
        </div>
        <div id='footer'>
          WCCD v{{ applicationVersion }} - &copy; Copyright {{ theYear }}<br />
          Atea Systems Ltd.<br />
          <a href="https://www.ateasystems.com/support/how-to-article/wxcc-dashboard-updates" target="_blank">ChangeLog</a><br/>All rights reserved.
        </div>
      </div>
      <transition v-if="isMounted && isCreated" name='fade' mode='out-in'>
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { version } from '../package.json'
// import ZoomNav from '@/modules/zoom/components/ZoomNav'
import WxccNav from '@/modules/wxcc/components/WxccNav'
import CodeService from '@/modules/wxcc/service/CodeService'
export default {
  data () {
    return {
      theYear: new Date().getFullYear(),
      isAuthenticated: false,
      message: '',
      renewalTimer: null,
      applicationVersion: version,
      isMounted: false,
      isCreated: false
      // redUri: process.env.NODE_ENV === 'development'
      //   ? 'http://localhost:8080/'
      //   : 'https://dashboard.wxcc.ateasystems.com/'
    }
  },
  components: {
    WxccNav
    // ZoomNav
  },
  // created () {
  //   this.$auth.setStore(this.$store)
  // },
  methods: {
    login () {
      this.$auth.login()
    },
    logout () {
      if (this.renewalTimer !== null) window.clearTimeout(this.renewalTimer)
      this.$auth.logout()
    }
  },
  computed: {
    ...mapGetters({
      isLoggedInAuth0: 'auth/isLoggedIn',
      // isWebexAuth: 'wxcc/isWebexAuth',
      showNav: 'auth/showNav',
      wxccCustomerId: 'wxcc/wxccCustomerId',
      apiServer: 'wxcc/apiServer'
    }),
    isLoggedIn () {
      return this.$auth.isAuthenticated()
    },
    userNickname () {
      return this.isLoggedInAuth0 ? this.$auth.user.name : ''
    },
    userAvatar () {
      return this.isLoggedInAuth0 ? this.$auth.user.picture : ''
    },
    isUserAdmin () {
      if (
        this.isLoggedInAuth0 &&
        this.$auth.user['http://cloud-portal/scope'].indexOf('read:users') > -1
      ) {
        return true
      }
      return false
    }
  },
  async created () {
    this.$auth.setStore(this.$store)
    // this.$auth.setStore(this.$store)
    const urlParams = new URLSearchParams(window.location.search)
    // console.log('route', urlParams.get('at'))
    // console.log('route', this.$route)
    if (urlParams.get('at')) {
      console.log('mounted route public link, skip auth')
      const data = await CodeService.getApiServer('at', urlParams.get('at'))
      console.log('got api server data', data)
      if (data.statsServer.startsWith('localhost')) {
        this.$store.commit('wxcc/setApiServer', `http://${data.statsServer}`)
      } else {
        this.$store.commit('wxcc/setApiServer', `https://${data.statsServer}`)
      }
    } else {
      try {
        console.log('mounted auth link renew tokens')
        const authResult = await this.$auth.renewTokens()
        console.log('mounted auth link renew tokens authResult', authResult)
        if (authResult.accessToken) {
          const isAuth = await this.$auth.isAuthenticated()
          console.log('auth', isAuth)
          console.log('auth', this.$auth.user['http://cloud-portal/roles'])
          this.$store.commit('auth/setIsAuthenticated', isAuth)
          this.$store.commit('auth/setRoles', this.$auth.user['http://cloud-portal/roles'])
          const wxccCustomerId = this.$auth.user['http://cloud-portal/customerId'].split(',')[0]
          this.$store.commit('wxcc/setWxccCustomerId', wxccCustomerId)
          this.$store.commit('wxcc/setIsWebexAuth', true)
        }
        console.log('getting api server data cid', this.wxccCustomerId)
        const data = await CodeService.getApiServer('cid', this.wxccCustomerId)
        console.log('got api server data', data)
        if (data.statsServer.startsWith('localhost')) {
          this.$store.commit('wxcc/setApiServer', `http://${data.statsServer}`)
        } else {
          this.$store.commit('wxcc/setApiServer', `https://${data.statsServer}`)
        }
        // this.$store.commit('wxcc/setApiServer', data.statsServer)
      } catch (err) {
        console.error(err)
      }
    }
    // console.log('after await renewTokens', this.$auth.isAuthenticated())
    // if (this.$auth.isAuthenticated()) {
    //   this.$store.commit('auth/setIsAuthenticated', true)
    //   this.$store.commit('auth/setRoles', this.$auth.user['http://cloud-portal/roles'])
    // }
    // if (localStorage.user) {
    //   const user = await JSON.parse(localStorage.user)
    //   this.$store.commit('auth/setRoles', user['http://cloud-portal/roles'])
    // }
    console.log('Created App')
    this.isCreated = true
  },
  mounted () {
    console.log('Mounted App')
    this.isMounted = true
  }
}
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fff;
}
#footer {
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 12px;
  width: 15rem;
}
.router-link-exact-active {
  color: #a12321 !important;
  font-weight: bold;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}
#page-content {
  margin-top: 20px;
  margin-left: 20px;
  /* height: 100%; */
}
.page-header-wrapper {
  width: 100%;
}
#page-content-wrapper .page-header-wrapper {
  padding: 0.55rem 1.25rem;
  font-size: 1.2rem;
}
#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
</style>
